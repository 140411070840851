import {JuridicalPerson} from './constitution';
import {MarriageRelationship} from './formconvenio';
import {Address, User} from './user';

export class Contrato {
  public user_agent: string;
  public close_task_interactions: boolean;
  public juridical_person_id: number;
  public juridical_person: number;
  public highlighted: boolean;
  public reason_final?: string;
  public reason_to_close?: string;
  public desisted_claim_payments?: boolean;
  public stop_desisted_claim_payments?: boolean;

  constructor(
    public id?: number,
    public contract_id?: number,
    public name?: string,
    public product_id?: number,
    public customer?: number,
    public customer_2?: number,
    public user_customer?: User,
    public user_customer_2?: User,
    public lawyer?: string,
    public notary?: string,
    public attorney?: string,
    public start_date?: Date,
    public validate_customer?: Boolean,
    public validate_customer_2?: Boolean,
    public validate_customer_date?: Date,
    public validate_customer_2_date?: Date,
    public modified_date?: Date,
    public actual_status?: string,
    public common_children?: boolean,
    public common_patrimony?: boolean,
    public assign_marital_residence?: string,
    public state?: string,
    public product_description?: string,
    public auto_number_register?: string,
    public associate_court_record?: string,
    public next_step_title?: string,
    public service_descriptions?: string,
    public customer_2_complete_name?: string,
    public product?: number,
    public marriagerelationship?: MarriageRelationship,
    public product_subtype?: string,
    public actual_substep_description?: string,
    public actual_sub_step_tracking_audit_due_date?: Date,
    public deceased_first_name?: string,
    public deceased_last_name?: string,
    public causative_residence_country?: string,
    public spain_goods?: boolean,
    public death_date?: Date,
    public deceased_goods?: boolean,
    public deceased_assets?: boolean,
    public social_denomination?: string,
    public CIF?: string,
    public has_properties?: boolean,
    public has_creditors?: boolean,
    public address?: Address,
    public has_workers?: string,
    public passive_value?: number,
    public active_value?: number,
    public constitution_date?: Date,
    public insolvency_cause?: String,
    public additional_pension_WR?: boolean,
    public additional_pension_CP?: boolean,
    public pre_payment_link?: string,
    public son?: any,
    public register_type?: string,
    public custody?: any,
    public customer_id?: Number,
    public special_case?: boolean,
    public reference?: string,
    public default_agreement?: boolean,
    public ibi?: string,
    public contract_price?: Number,
    public contracted?: boolean,
    public need_arras_sell?: boolean,
    public need_arras_buy?: boolean,
    public contract_type?: string,
    public register_property_buy?: any,
    public send_contract?: boolean,
    public court_province?: string,
    public court_municipality?: string,
    public constitution_start_date?: Date,
    public source?: string,
    public province?: string,
    public send_previous_doc?: boolean,
    public robot_link?: string,
    public end_date?: Date,
    public is_salable?: boolean,
    public tier?: string,
    public comments?: string,
    public responsible_id?: number,
    public responsible?: string,
    public department_id?: number,
    public department?: string,
    public trademark_to_register?: string,

    public send_email_planned_payment_reminder?: boolean,
    public send_sms_planned_payment_reminder?: boolean,
    public selected_person_type?: string
  ) {
    if (typeof window !== 'undefined' && window.navigator) {
      this.user_agent = window.navigator.userAgent;
    }
  }

  public getProductDetail(): string {
    let productDetail = '';
    if (this.product == ProductsID.divorce_product_id || !this.product) {
      if (this.actual_status == 'married') {
        if (this.product_subtype == 'separation') {
          productDetail = 'separación';
        } else {
          productDetail = 'divorcio';
        }
      } else if (this.actual_status == 'legally separated') {
        productDetail = 'divorcio';
      } else if (this.actual_status == 'common-law couple') {
        productDetail = 'extinción de la pareja de hecho';
      }
    } else if (this.product == ProductsID.inheritance_product_id) {
      productDetail = 'herencia';
    } else if (this.product == ProductsID.creditors_arrangement_id) {
      productDetail = 'concurso de acreedores';
    } else if (this.product == ProductsID.down_payment_id) {
      if (this.default_agreement) {
        productDetail = 'penitenciales';
      } else {
        productDetail = 'confirmatorias';
      }
    } else if (this.product == ProductsID.creditors_pre_arrangement_id) {
      productDetail = 'preconcurso';
    } else if (this.product == ProductsID.buy_sell_contract) {
      if (this.need_arras_buy == true || this.need_arras_sell == true) {
        productDetail = 'arras y compraventa';
      } else {
        productDetail = 'compraventa';
      }
    } else if (this.product == ProductsID.limited_society) {
      productDetail = 'constitución de tu empresa';
    }
    return productDetail;
  }

  // Add compraventa (Contrato de compraventa // Contrato de arras y compraventa)

  public getDivorceDetail(): string {
    let divorceDetail = '';
    if (this.actual_status == 'married') {
      if (this.product_subtype == 'separation') {
        divorceDetail = 'separación de mutuo acuerdo';
      } else {
        divorceDetail = 'divorcio de mutuo acuerdo';
      }
    } else if (this.actual_status == 'legally separated') {
      divorceDetail = 'divorcio';
    } else if (this.actual_status == 'common-law couple') {
      divorceDetail = 'extinción de pareja de hecho';
    }
    return divorceDetail;
  }

  public getThankYouTexts(): Object {
    let message = '';
    let nosEncargamos = '';
    if (this.product_id == ProductsID.divorce_product_id) {
      message = this.getDivorceDetail();
      nosEncargamos = 'DEL RESTO NOS ENCARGAMOS NOSTROS...';
    } else if (this.product_id == ProductsID.inheritance_product_id) {
      message = 'herencia';
      nosEncargamos = 'NOSOTROS NOS ENCARGAMOS DE TODO…';
    } else if (this.product_id == ProductsID.creditors_arrangement_id) {
      message = 'concurso';
      nosEncargamos = 'NOSOTROS NOS ENCARGAMOS DE TODO…';
    } else if (this.product_id == ProductsID.creditors_pre_arrangement_id) {
      message = 'preconcurso';
      nosEncargamos = 'NOSOTROS NOS ENCARGAMOS DE TODO…';
    }

    let thankYouTexts = {message, nosEncargamos};
    return thankYouTexts;
  }

  public getIsCirceCommunitie(province): Object {
    let not_circe_communities = [
      'LAS PALMAS',
      'GUIPUZKOA',
      'NAVARRA',
      'CEUTA',
      'MELILLA',
      'ARABA',
    ];

    let is_not_circe_communitie = not_circe_communities.includes(province);

    return is_not_circe_communitie;
  }
}

export class ContractedService {
  constructor(
    public id?: number,
    public service_description?: string,
    public marriagerelationship?: MarriageRelationship,
    public product_subtype?: string,
    public service_id?: number,
    public price?: number,
    public service_name?: string,
    public service_contracted?: boolean,
    public good_id?: number,
    public son_id?: number
  ) {}
}

export class ContractAnalytics {
  public contract_id: number;
  public origin_responsible_id: number;
  public origin_department_id: number;
  public campaign: string;
  public source: string;
  public medium: string;
}

export class ProductsID {
  static readonly divorce_product_id: number = 1;
  static readonly inheritance_product_id: number = 2;
  static readonly creditors_arrangement_id: number = 3;
  static readonly down_payment_id: number = 4;
  static readonly creditors_pre_arrangement_id: number = 5;
  static readonly buy_sell_contract: number = 6;
  static readonly limited_society: number = 7;
  static readonly will: number = 10;
  static readonly virtual_office: number = 11;
  static readonly agency: number = 12;
  static readonly labor_agency: number = 30;
  static readonly second_chance_law: number = 37;
  static readonly patents_and_trademarks: number = 49;
  static readonly notarized_divorce: number = 88;
}

export const NEED_JURIDCIAL_PERSON_PRODUCTS = [
  ProductsID.agency,
  ProductsID.labor_agency,
  ProductsID.virtual_office,
];

export class CurrentPaymentStep {
  constructor(public step: number) {}
}

export class ContractFilter {
  constructor(
    public pending?: boolean,
    public profile?: number,
    public customer?: string,
    public spouse?: string,
    public autos?: string,
    public court?: string,
    public lawyer?: string,
    public attorney?: string,
    public contract?: string,
    public state?: string,
    public product?: string,

    public admin_url?: boolean,
    public limit: number = 5,
    public offset: number = 0,
    public count?: number,
    public first: number = 1,
    public last?: number,
    public profile_url: boolean = false,
    public next_url?: string,
    public previous_url?: string
  ) {}
}

export class Modification {
  public modification_date: Date;
  public id: number;
  public modification_response: Modification;
  public user: User;
  public verified_by_id: number;
  public verified_by_user: User;
  public validated_date: Date;

  constructor(
    public contract_id: number,
    public customer_id: number,
    public text: string,
    public is_verified: boolean = false
  ) {}
}
